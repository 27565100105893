import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.tsx";
import { toast } from "react-toastify";
import SignIn from "../SignIn/SignIn";

export default function JudgePortal() {
    const isDev = window.location.host.indexOf("localhost:") > -1;
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null);
  const [accessLinks, setAccessLinks] = useState([]);
  const [clients, setClients] = useState([]);
  const [showContests, setShowContests] = useState(false);
  const [links, setLinks] = useState({});

  useEffect(() => {
    Init();
  }, []);

  const Init = () => {
    store.server.getApi("../JudgePortal/Info").then((r) => {
      if (r.Success) {
        toast.success(`Hi ${r.Value.user.first_name}.`);
        setClients(r.Value.clients);
        setAccessLinks(r.Value.accessLinks);
        setUser(r.Value.user);

        let ls = {};
        r.Value.accessLinks.forEach((x) => {
          ls[x.clientId] = x;
        });

        setLinks(ls);
      } else if (r.LoginRequired) {
        requestLogin();
      }
      setReady(true);
    });
  };
  const AddNewContest = () => {
    toast.dark(
      `Hi ${user.first_name}, here is the list of all of the contests.`
    );
    setShowContests(true);
  };

  const SignInToFair = (fair) => {
    toast.info(
      `Hey ${user.first_name}, we are logging you into ${fair.fair_name}. Hold on!`
    );
    

    if (isDev) {
      let url = `http://localhost:8001/JudgePortal/SsoRequest/${fair.fairId}`;
      console.log(url);
      window.location = url;
    } else {
      window.location = `../JudgePortal/SsoRequest/${fair.fairId}`;
    }
  };

  const SignOut=()=>{
      toast.info('We are signing you out.');
      setUser(null);

      if (isDev) {
        let url = `http://localhost:8001/Call/SignOut`;
        console.log(url);
        window.location = url;
      } else {
        window.location = `../Call/SignOut`;
      }
  };

  const requestAccess = (client) => {
    store.server
      .postApi("../JudgePortal/Request", { fairId: client.fair.idInt })
      .then((r) => {
        if (r.LoginRequired) {
          requestLogin();
        } else if (r.Success) {
          toast.info(
            `${user.first_name} we requested access for you to be a judge for ${client.name}.`
          );
          let als = accessLinks;
          als.push(r.Value);

          let ls = {};
          als.forEach((x) => {
            ls[x.clientId] = x;
          });

          //setClients(clients);

          setLinks(ls);
        } else {
          toast.error(
            `Hey ${user.first_name}, it looks like something went wrong. ${r.Message}`
          );
        }
      });
    toast.dark(`Let's see what we can do.`);
  };

  const requestLogin = () => {
    setUser(null);
    toast.warning("You need to sign in.");
  };

  return (
    <>
      <div className="judge-portal-container">
        <div className="judge-portal">
          <h1>
            <i className="fad fa-gavel"></i>Multi-Contest Judge Access
          </h1>
          {ready && (
            <div>
              <div className="head-room-1"></div>
              {!user && <SignIn signedIn={Init}></SignIn>}
              {user && (
                <div>
                  {!showContests && (
                    <>
                      <div>
                        Hi {user.first_name},
                        <br />
                        <br />
                        Welcome to your Multi-Contest Judge Access portal! This page is where you will find and manage the contests you are judging. 
                        You can also use this page to request to be a judge at multiple contests. To request to be a 
                        judge at additional contests, click the green Add button, then find and select the contest you wish to judge.
                      </div>
                      <div>
                        <div className="head-room-l"></div>
                        <h3>Quick Sign In</h3>
                        <div className="my-contest-list">
                          {accessLinks.map((x, i) => {
                            return (
                              <div key={`contest-${x.id}`} className="contest">
                                <div className="contest-state">
                                  {x.state}&nbsp;
                                </div>
                                <div className="contest-title">
                                  {x.fair_name}
                                </div>

                                {x.approved && !x.removed && (
                                  <div
                                    className="contest-url btn btn-secondary"
                                    onClick={() => {
                                      SignInToFair(x);
                                    }}
                                  >
                                    Sign In at {x.binding}
                                  </div>
                                )}
                                {x.removed && (
                                  <div className="alert bg-danger text-light">
                                    Removed
                                  </div>
                                )}
                                {!x.approved && (
                                  <div className="alert bg-warning text-light">
                                    pending
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          <div className="contest">
                            <div className="contest-state"></div>
                            <div className="contest-title"></div>

                            <div
                              className="contest-url btn btn-success"
                              onClick={() => {
                                AddNewContest();
                              }}
                            >
                              <i className="fad fa-plus-circle"></i>Add
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showContests && (
                    <div>
                      <div>
                        {user.first_name}, find and select the contest(s) you wish to judge. If approved by the contest admin, the contest will appear on your Multi-Contest Judge Access portal.
                      </div>
                      <div className="head-room-l"></div>
                      <div className="sticky flex-between">
                        <div>{/* place search here */}</div>
                        <div>
                          <div
                            className="alert btn-secondary click"
                            onClick={() => {
                              setShowContests(false);
                            }}
                          >
                            Close
                          </div>
                        </div>
                      </div>
                      <div className="my-contest-list">
                        {clients &&
                          clients.map((x, i) => {
                            if (x == null || !x) return null;

                            let link = links[x.id];
                            console.log("Link", link);

                            return (
                              <div
                                key={`contest-all-${x.id}`}
                                className="contest"
                              >
                                <div className="contest-state">
                                  {x.state}&nbsp;
                                </div>
                                <div className="contest-title">{x.name}</div>

                                {link && link.approved && !link.removed && (
                                  <div
                                    className="contest-url btn btn-secondary"
                                    onClick={() => {
                                      SignInToFair(x);
                                    }}
                                  >
                                    Sign In at {x.binding}
                                  </div>
                                )}
                                {link && link.removed && (
                                  <div className="alert bg-danger text-light">
                                    Removed
                                  </div>
                                )}
                                {link && !link.approved && !link.removed && (
                                  <div className="alert bg-warning text-light">
                                    pending
                                  </div>
                                )}
                                {!link && (
                                  <div
                                    className="contest-url btn-success"
                                    onClick={() => {
                                      requestAccess(x);
                                    }}
                                  >
                                    request access to judge
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div>
            {user && (
              <div>
                <span>
                  <i className="fad fa-user"></i> {user.first_name}
                </span>{" "}
                <span className="button click text-secondary" onClick={SignOut}>Sign Out</span>
              </div>
            )}
          </div>{" "}
        </div>
      </div>
    </>
  );
}
