import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from './LandingPage/landing';
import JudgePortal from './JudgePortal/JudgePortal';


export default (
    <Switch>
        <Route path='/app/judge-portal' component={JudgePortal}/>
        <Route path='/' component={Landing} />
    </Switch>
  )