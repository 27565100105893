import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.tsx";
import { toast } from "react-toastify";
import Register from "../Register/Register";

export default function SignIn(props) {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const register = () => {
    setIsRegistering(true);
  };

  const cancelRegister = () => {
    setIsRegistering(false);
  };

  const SignUserIn = () => {
    let request = {
        email,
        password
    };

    if(!email || email.length < 4 || email.indexOf('@') === -1 || email.indexOf('.') === -1){
        toast.info(`Please enter a valid email. You have entered "${email}"`);
        return;
    }
    if(!password || password.length < 4){
        toast.info('Please enter a valid password.');
        return;
    }

    store.server.postApi("../Call/SignIn", request).then((r) => {
      if (r.Success) {
        props.signedIn();
      } else {
        toast.warning(r.Message);
      }
    });
  };

  return (
    <>
      {!isRegistering && (
        <div className="sign-in-container">
          <h3>Sign In</h3>
          <div className="form-input">
            <label>Email</label>
            <input type="text" maxLength="200" placeholder="email" onKeyUp={(e)=>{setEmail(e.target.value);}} />
          </div>

          <div className="form-input">
            <label>Password</label>
            <input type="Password" maxLength="50" placeholder="password" onKeyUp={(e)=>{setPassword(e.target.value)}} />
          </div>
          <div className="form-input">
            <button type="button" className="btn-primary" onClick={SignUserIn}>
              sign-in
            </button>
          </div>
        </div>
      )}
      {isRegistering && (
        <Register cancelRegistration={cancelRegister}></Register>
      )}
    </>
  );
}
