import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.tsx";
import { toast } from "react-toastify";

export default function Register(props) {

const cancelRegistration = ()=>{
    props.cancelRegistration();
};

  return (
    <>
      {true && (
        <div className="sign-in-container">
          <h3>Register</h3>

          
          <div className="form-input">
            <label>First Name</label>
            <input type="text" maxLength="200" placeholder="first name" />
          </div>
          
          <div className="form-input">
            <label>Last Name</label>
            <input type="text" maxLength="200" placeholder="last name" />
          </div>

          <div className="form-input">
            <label>Email</label>
            <input type="text" maxLength="200" placeholder="email" />
          </div>

          <div className="form-input">
            <label>Password</label>
            <input type="Password" maxLength="50" placeholder="password" />
          </div>
          <div className="form-input">
            <label>Confirm </label>
            <input type="Password" maxLength="50" placeholder="confirm password" />
          </div>
          <div className="form-input">
            <button type="button" className="btn-primary">
              register
            </button>
            <span className="click" onClick={cancelRegistration}>
              cancel
            </span>
          </div>
        </div>
      )}
    </>
  );
}
