import React, { useState, useEffect } from "react";
import { store } from "../../redux/oldStore.tsx";
import { toast } from "react-toastify";

export default function Landing() {
  const goto = (id) => {
    switch (id) {
      case "NHDWebCentral":
        window.location = "https://nhdwebcentral.org";
        break;
        case "FindContest":
          window.location = "https://register.nhd.org/Contests";
          break;
          case "JudgePortal":
            window.location = "/app/judge-portal";
            break;
    }
  };

  return (
    <>
      <div className="landing-page-container">
        <div className="landing-page-options">

        <div
        className="option"
        onClick={() => {
          goto("FindContest");
        }}
      >
        <div className="title">Contest Portal</div>
        <div className="logo">
          <img
            className="main-logo"
            src="https://register.nhd.org/Assets/Images/nhdlogo.png"
            alt="NHD logo"
          ></img>
        </div>
        <div className="description">Find your NHD contest.</div>
        <div className="click-select text-primary">click to select</div>
      </div>

          <div
            className="option"
            onClick={() => {
              goto("NHDWebCentral");
            }}
          >
            <div className="title">NHDWebCentral&#8482;</div>
            <div className="logo">
              <img
                src="https://website.nhd.org/Assets/images/NHDWCCircle256.png"
                alt="NHDWebCentral &#8482; Logo"
              ></img>
            </div>
            <div className="description">
            Build your NHDWebCentral&trade; project.
            </div>
            <div className="click-select text-primary">click to select</div>
          </div>


          <div className="option"
          onClick={() => {
            goto("JudgePortal");
          }}>
            <div className="title">Multi-Contest Judge Access</div>
            <div className="logo">
              <i className="fad fa-10x fa-gavel"></i>
            </div>
            <div className="description">
            Sign in to your multi-contest judge account. 
            </div>
            <div className="click-select text-primary">click to select</div>
          </div>
        </div>
      </div>
    </>
  );
}
