import {Server} from '../utils/Server'

export class Store{
    server:Server|null;
    user:any|null;
    public static instance:Store|null = null;
    constructor(){
        if(Store.instance){
            this.server = Store.instance.server;
            this.user = Store.instance.user;
        } else {
            Store.instance = this;
        }
        this.server = null;
        this.user = null;
    }

    public getInstnace(){
        return Store.instance
    }

    public init(loadingScreen:any){
        if(!Store.instance) {
            Store.instance = this;
            //return;
        }

        if(!Store.instance.server){
            Store.instance.server = new Server(loadingScreen);
            console.log('################  init  ########################');    
        }
        
    }
}

const store = new Store();
export {store}