import React from 'react'
import { connect } from 'react-redux'
import './loading.css'

const Loading = (props) => {
  return(
    <div className="loading-wrapper">
    <div className="wrapper">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <span>{props.loadingText || 'Loading'}</span>
    </div>
    </div>
  )
}

const mapStateToProps = (reduxState) => {
  return reduxState.main
}

export default connect(mapStateToProps, {})(Loading)