import React, { useState, useEffect } from "react";
import Loading from "./Components/LoadingScreen/loading.jsx";
import routes from "./Components/routes.jsx";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { store } from "./redux/oldStore.tsx";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import "./Base.css";
import "./App.css";

toast.configure();

function App(props) {
  const [settings, setSettings] = useState(null);
  const [testShimPass, setTestShimPass] = useState(false);
  
  useEffect(()=>{
    store.server.postApi('../shim/test').then(r=>{
      console.log(r);
      if(r.Success){
        setTestShimPass(true);
      }
    });

  }, []);

  
  const toggleLoading = (on, text) => {
    if (!text) text = "Loading";
  };
  store.init(toggleLoading);


  return (
    <>
    <div className="App">
      <div className="container">
        <div className="title-bar primary-bg">
          <div className="logo"></div>
          <div>
          </div>
        </div>
        <div className="head-room"></div>
        <div className="body">
        {routes}
        </div>
        <div className="head-room-xl"></div>
        <div className="footer primary-bg">
          <div>
            <img
              src="https://zfairs.com/Content/Images/zFairsLogo.png"
              alt="zFairs logo"
            />
          </div>          
        </div>
      </div>
    </div>
    {props.loading && <Loading></Loading>}
    </>
  );
}

const mapStateToProps = (reduxState) => {
  return reduxState.main;
};

export default withRouter(connect(mapStateToProps, {})(App));
