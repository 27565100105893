let initialState = {
    server:null,
    loading: false,
    loadingText: 'Loading'
};


export const startLoading = () => {
    return {
        type: 'START_LOADING'
    }
}
export const endLoading = () => {
    return {
        type: 'END_LOADING'
    }
}

export const setLoadingText = (string) => {
    return {
        type: 'SET_LOADING_TEXT',
        payload: string
    }
}

export default function reducer(state = initialState, action){
    let { type, payload } = action
    switch(type) {
        case 'START_LOADING':
            return Object.assign({}, state, { loading: true });
        case 'SET_LOADING_TEXT':
            return Object.assign({}, state, { loadingText: payload })
        case 'END_LOADING':
            return Object.assign({}, state, { 
                loading: false, 
                loadingText: 'Loading' 
            });
        default:
            return state;
    }
}