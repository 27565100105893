export const SET_PROJECTS = 'SET_PROJECTS' 
export const SET_CATEGORIES = 'SET_CATEGORIES' 
export const SET_ROUNDS = 'SET_ROUNDS' 
export const SET_SELECTED_ROUND = 'SET_SELECTED_ROUND' 
export const SET_SELECTED_ROUND_INDEX = 'SET_SELECTED_ROUND_INDEX' 
export const SET_PAGE = 'SET_PAGE' 
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT' 
export const SET_SELECTED_JUDGE = 'SET_SELECTED_JUDGE' 
export const SET_JUDGES = 'SET_JUDGES' 
export const SET_JUDGES_BY_KEY = 'SET_JUDGES_BY_KEY' 
export const SET_PROJECTS_BY_KEY = 'SET_PROJECTS_BY_KEY' 
export const SET_ASSIGNMENTS = 'SET_ASSIGNMENTS'
export const SET_PROJECTS_ASSIGNMENTS = 'SET_PROJECTS_ASSIGNMENTS'
export const SET_JUDGES_ASSIGNMENTS = 'SET_JUDGES_ASSIGNMENTS'
